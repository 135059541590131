<template>
  <Layout class="CreateOrEditPrize">
    <el-form
      ref="form"
      :rules="rules"
      :model="formData"
      inline
    >
      <el-form-item
        label="商品标题"
        prop="prizeTitle"
      >
        <el-input
          v-model="formData.prizeTitle"
          style="width: 300px"
        />
      </el-form-item>
      <header class="header">
        宣传图（横）
        <i
          class="fa fa-pencil-square-o icon"
        />
      </header>
      <el-form-item prop="promotionalImageList">
        <UploadImageMultiple
          preview-style="width: 200px; height: 120px;"
          :tips="'至少四张, 不限宽高'"
          :file-list.sync="formData.promotionalImageList"
        />
      </el-form-item>
      <header class="header">
        图标
        <i
          class="fa fa-pencil-square-o icon"
        />
      </header>
      <el-form-item prop="icon">
        <UploadImage2
          preview-style="width: 150px; height: 150px;"
          :ratio="[1, 1]"
          :show-close="true"
          :url.sync="formData.icon"
        />
      </el-form-item>
      <br>
      <el-form-item>
        <el-button
          style="width: 150px;"
          type="primary"
          plain
          @click="handleConfirm"
        >
          保存
        </el-button>
      </el-form-item>
    </el-form>
  </Layout>
</template>

<script>
import {
  createOneDollarPrize,
  updateOneDollarPrize,
  getOneDollarPrizeDetail
} from '@/api/LuckyBuy.js'
export default {
  data () {
    const promotionalImageList = (rule, value, callback) => {
      if (!value || value.length < 4) {
        callback(new Error('至少上传4张宣传图'))
      } else {
        callback()
      }
    }
    return {
      isEdit: true,
      formData: {},
      rules: {
        prizeTitle: [
          { required: true, message: '商品标题必填' }
        ],
        promotionalImageList: [
          { required: true, validator: promotionalImageList }
        ],
        icon: [
          { required: true, message: '图标必传' }
        ]
      }
    }
  },
  created () {
    this.isEdit = this.$route.name === 'PrizeEdit'
    if (this.isEdit) {
      this.getOneDollarPrizeDetail()
    }
  },
  methods: {
    getOneDollarPrizeDetail () {
      getOneDollarPrizeDetail({ id: this.$route.query.id })
        .then(res => {
          if (res.code === 200) {
            const {
              id,
              prizeTitle,
              icon,
              promotionalImageList
            } = res.data
            this.formData = {
              id,
              prizeTitle,
              icon,
              promotionalImageList
            }
          }
        })
    },
    handleConfirm () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.isEdit) {
            this.handleEditConfirm()
          } else {
            this.handleCreateConfirm()
          }
        }
      })
    },
    handleEditConfirm () {
      updateOneDollarPrize(this.formData)
        .then(res => {
          if (res.code === 200) {
            this.$router.go(-1)
          }
        })
    },
    handleCreateConfirm () {
      createOneDollarPrize(this.formData)
        .then(res => {
          if (res.code === 200) {
            this.$router.go(-1)
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
  .CreateOrEditPrize {
    border: 1px solid transparent;
    box-sizing: border-box;
    header {
      margin: 40px 0 20px;
      padding-left: 20px;
      font-size: 14px;
      border-left: 2px solid #999;
    }
  }
</style>
